export default [
  {
    name: 'Ana Paula Gomes',
    role: 'Faz-tudo',
    picture: '/assets/volunteers/anapaulagomes.jpeg',
    github: 'anapaulagomes',
    linkedin: 'anapaulagomess',
    twitter: 'anapaulagomess',
  },
  {
    name: 'Filipe Maia',
    role: 'Desenvolvedor',
    picture: '/assets/volunteers/filipebsmaia.png',
    github: 'filipebsmaia',
    linkedin: 'filipebsmaia',
  },
  {
    name: 'Matheus Rocha',
    role: 'Desenvolvedor',
    picture: '/assets/volunteers/matheusrocha89.jpeg',
    github: 'matheusrocha89',
    linkedin: 'matheus-cruz-rocha-38021525',
    twitter: 'matheusrocha',
  },
  {
    name: 'Emerson Oliveira',
    role: 'Desenvolvedor',
    picture: '/assets/volunteers/emersonsdo.jpeg',
    github: 'emersonsdo',
    linkedin: 'emerson-oliveira-89ab859',
  },
  {
    name: 'Vivian Macedo',
    role: 'Analista de Dados',
    picture: '/assets/volunteers/vivianmacedo.jpeg',
    github: 'vihvs',
    linkedin: 'vivianmacedo',
  },
  {
    name: 'Rafael Gomes',
    role: 'Desenvolvedor',
    picture: '/assets/volunteers/gomex.png',
    github: 'gomex',
    linkedin: 'rbgomes',
    twitter: 'gomex',
  },
  {
    name: 'Rafael Velame',
    role: 'Jornalista',
    picture: '/assets/volunteers/rafaelvelame.jpeg',
    twitter: 'rafaelvelame',
    linkedin: 'rafael-velame-242b98104',
  },
  {
    name: 'Bruno Morassuti',
    role: 'Conselheiro Jurídico',
    picture: '/assets/volunteers/jedibruno.jpeg',
    github: 'jedibruno',
    twitter: '555112299jedi',
    linkedin: 'bruno-schimitt-morassutti-7a0b5b180',
  },
  {
    name: 'Nataly Costa',
    role: 'Designer',
    picture: '/assets/volunteers/natalycosta.jpeg',
    linkedin: 'natalycosta',
  },
  {
    name: 'Eduardo Cuducos',
    role: 'Desenvolvedor',
    picture: '/assets/volunteers/cuducos.jpeg',
    github: 'cuducos',
    twitter: 'cuducos',
  },
  {
    name: 'Daniel Fireman',
    role: 'Conselheiro',
    picture: '/assets/volunteers/danielfireman.jpeg',
    github: 'danielfireman',
    linkedin: 'danielfireman',
    twitter: 'daniellfireman',
  },
  {
    name: 'Everton Alves',
    role: 'Social Media',
    picture: '/assets/volunteers/ellalves.png',
    instagram: 'ellalves.mktd',
  },
  {
    name: 'Guilherme Wanderley',
    role: 'Office-boy e Analista de Dados',
    picture: '/assets/volunteers/guilhermelowa.jpeg',
    github: 'guilhermelowa',
    linkedin: 'guilherme-wanderley',
  },
  {
    name: 'Luanda Dantas',
    role: 'Desenvolvedora',
    picture: '/assets/volunteers/luandadantas.jpeg',
    github: 'luandadantas',
    linkedin: 'luandadantas',
  },
  {
    name: 'Geraldo Castro',
    role: 'Desenvolvedor',
    picture: '/assets/volunteers/exageraldo.jpg',
    github: 'exageraldo',
    linkedin: 'exageraldo',
    twitter: 'exageraldo_',
  },
  {
    name: 'Felipe Barros',
    role: 'Analista de Dados',
    picture: '/assets/volunteers/felipelfb.jpeg',
    github: 'felipelfb',
    linkedin: 'felipe-barros-609a52141',
  },
  {
    name: 'Gabriela Turquetti',
    role: 'Desenvolvedora',
    picture: '/assets/volunteers/turquetti.jpeg',
    github: 'turquetti',
    linkedin: 'gabriela-turquetti',
    instagram: 'gabrielaturquetti',
    twitter: 'gabturquetti',
  },
  {
    name: 'Nathanael Souza',
    role: 'Desenvolvedor',
    picture: '/assets/volunteers/nathanaelss.jpeg',
    github: 'Nathanaelss',
    linkedin: 'nathanael-souza',
  },
  {
    name: 'Allythy',
    role: 'Desenvolvedor',
    picture: '/assets/volunteers/allythy.jpeg',
    github: 'allythy',
  },
  {
    name: 'Eduardo Daltro',
    role: 'Staff',
    picture: '/assets/volunteers/daltroedu.jpeg',
    linkedin: 'daltroedu',
  },
  {
    name: 'André Costa',
    role: 'Designer',
    picture: '/assets/volunteers/andrecostaux.jpeg',
    linkedin: 'andrecostaux',
  },
].sort((person1, person2) => {
  return person1.name.localeCompare(person2.name); // Automatically alphabetize
});

/* Example of Person to add in list
{
  name: 'Filipe Maia',
  role: 'Desenvolvedor',
  picture: '/assets/volunteers/filipebsmaia.png',
  github: 'filipebsmaia',
  linkedin: 'filipebsmaia',
  instagram: 'filipebsmaia',
  twitter: 'filipebsmaia',
}
*/
